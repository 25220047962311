<template>
  <v-container justify-center fluid>
    <v-row>
      <v-bottom-navigation
        v-if="showForRoutes.includes(route.path.substring(1))"
        grow
        horizontal
        elevation="2"
        class="w-full"
      >
        <template v-for="navRoute of showForRoutes" :key="navRoute">
          <v-btn size="x-small pa-0" @click="navigateTo(navRoute)">
            <div v-if="route.path == `/${navRoute}`">
              <v-icon color="base0">
                {{ `$${navRoute}_selected` }}
              </v-icon>
              <p class="font-weight-bold text-primary">
                {{ $t(`bottomNav.${navRoute}`) }}
              </p>
            </div>
            <div v-else>
              <v-icon color="base0">
                {{ `$${navRoute}` }}
              </v-icon>
              <p>{{ $t(`bottomNav.${navRoute}`) }}</p>
            </div>
          </v-btn>
        </template>
      </v-bottom-navigation>
    </v-row>
  </v-container>
</template>

<script setup>
import router from '@/router';
import { useRoute } from 'vue-router';
import { ref } from 'vue';

// const showForRoutes = ref([
//     "dashboard",
//     "installations",
//     "monitoring",
//     "aftersale",
//     "support"
// ]);

const showForRoutes = ref([
  'installations',
  'support',
]);

const route = useRoute();

function navigateTo(name) {
  router.push({ name: `${name}` });
}

</script>

<style scoped>
p {
    font-size: 0.9em;
    color: rgb(var(--v-theme-base400))
}
</style>
