import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import apiClient from '@/plugins/api-client';
import { getApiRoot } from '@/helpers/api';
import { getKeycloak } from '@/helpers/keycloak';
import { getLocalstorageItem, setLocalstorageItem } from '@/helpers/localStorage';
import { getSubdomain } from '@/helpers/util';
import { useApi } from '@/composables/useApi';

export const useIssueStore = defineStore('issueStore', () => {
  const { apiGet } = useApi();

  const issues = ref([]);
  const issuesTypes = ref([]);
  const StatusExpirationDays = 10;

  const issuesOpen = computed(() => issues.value.filter((issue) => !issue.final));
  const issuesClosed = computed(() => issues.value.filter((issue) => issue.final));

  async function getIssues() {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        `${getApiRoot()}/issues/`,
        { headers },
      );
      if (response.data.errors.length > 0) {
        throw new Error(response.data.errors);
      }
      issues.value = response.data.data;
    }
    catch (error) {
      throw new Error(error);
    }
  }

  async function getIssueById(id) {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.get(
        `${getApiRoot()}/issues/${id}/messages`,
        { headers },
      );
      if (response.data.errors.length > 0) {
        throw new Error(response.data.errors);
      }
      return response.data.data;
    }
    catch (error) {
      throw new Error(error);
    }
  }

  async function getIssuesTypes() {
    issuesTypes.value = await apiGet('issues/issuesTypes');
  }

  async function sendMessage(id, message) {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.post(
        `${getApiRoot()}/issues/${id}/emails/sendMessage`,
        {
          ...message,
        },
        { headers },
      );
      if (response.data.errors.length > 0) {
        throw new Error(response.data.errors);
      }
      return response.data.data;
    }
    catch (error) {
      throw new Error(error);
    }
  }

  async function createIssue(issueData) {
    try {
      const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
      const response = await apiClient.post(
        `${getApiRoot()}/issues/create`,
        {
          problemTitle: issueData.problem,
          problem: issueData.description,
          orderId: issueData.installation?.OrderID,
          offerId: issueData.installation?.OfferID,
          typeId: issueData.type,
        },
        { headers },
      );
      if (response.data.errors.length > 0) {
        throw new Error(response.data.errors);
      }
      if (issueData.files.length === 0) {
        return response.data.data;
      }
      await uploadIssueFiles(issueData.files, response.data.data.insertId);
      return response.data.data;
    }
    catch (error) {
      throw new Error(error);
    }
  }

  async function uploadIssueFiles(files, issueID) {
    const headers = { Authorization: `Bearer ${(await getKeycloak()).token}` };
    const formData = new FormData();
    for (let file of files) {
      formData.append('files', file);
    }
    const response = await apiClient.post(
      `${getApiRoot()}/issues/${issueID}/file`,
      formData,
      { headers },
    );
    return response.data.data;
  }

  async function getIssueStatuses() {
    let issueStatuses = getLocalstorageItem('IssueStatuses');
    if (issueStatuses !== undefined) {
      return issueStatuses;
    }
    return (await parseStatusResponse())?.IssueStatuses;
  }

  async function parseStatusResponse() {
    const response = await apiClient.get(
      `${getApiRoot()}/issues/statuses`, {
        params: {
          subDomain: getSubdomain(),
          lang: 'nl-BE',
        },
      },
    );
    if (response.data.errors.length === 0) {
      let IssueStatuses = {};
      let InitialIssueStatus = null;
      let FinalIssueStatus = null;
      const data = response.data.data;
      for (const status of data) {
        IssueStatuses[status.status.split(' ').join('_')] = status.id;
      }
      InitialIssueStatus = data.filter((status) => status.initial)[0]?.id;
      FinalIssueStatus = data.filter((status) => status.final)[0]?.id;

      setLocalstorageItem('IssueStatuses', IssueStatuses, StatusExpirationDays);
      setLocalstorageItem('InitialIssueStatus', InitialIssueStatus, StatusExpirationDays);
      setLocalstorageItem('FinalIssueStatus', FinalIssueStatus, StatusExpirationDays);

      return {
        IssueStatuses: IssueStatuses,
        InitialIssueStatus: InitialIssueStatus,
        FinalIssueStatus: FinalIssueStatus,
      };
    }
    else {
      throw new Error(response.data.errors);
    }
  }

  async function getInitialIssueStatus() {
    let InitialIssueStatus = getLocalstorageItem('InitialIssueStatus');
    if (InitialIssueStatus !== undefined) {
      return InitialIssueStatus;
    }
    return (await parseStatusResponse())?.InitialIssueStatus;
  }

  async function getFinalIssueStatus() {
    let FinalIssueStatus = getLocalstorageItem('FinalIssueStatus');
    if (FinalIssueStatus !== undefined) {
      return FinalIssueStatus;
    }
    return (await parseStatusResponse())?.FinalIssueStatus;
  }

  return {
    issues,
    issuesTypes,
    issuesOpen,
    issuesClosed,
    getIssues,
    getIssuesTypes,
    getIssueStatuses,
    getInitialIssueStatus,
    getFinalIssueStatus,
    createIssue,
    uploadIssueFiles,
    getIssueById,
    sendMessage,
  };
});
